import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

const REDIRECT_URL = process.env.VUE_APP_ONEDRIVE_REDIRECT_URL

const msalConfig = {
    auth: {
        clientId: 'f3017237-0d78-48df-b65d-9e17d5556b3c',
        redirectUri: REDIRECT_URL, // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: REDIRECT_URL // Must be registered as a SPA redirectURI on your app registration
    },
    cache: {
        cacheLocation: 'localStorage' // Options are localStorage, sessionStorage, memoryStorage
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return	
                }
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message)	
                        return	
                    case LogLevel.Info:	
                        console.info(message)	
                        return	
                    case LogLevel.Verbose:	
                        console.debug(message)	
                        return	
                    case LogLevel.Warning:	
                        console.warn(message)	
                        return	
                    default:
                        return
                }
            },
            logLevel: LogLevel.Verbose
        }
    }
}

export const loginRequest = {
    scopes: ["User.Read", "Files.Read", "Files.Read.All", "Files.ReadWrite", "Files.ReadWrite.All", "Sites.Read.All", "Sites.ReadWrite.All"]
}

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphDrivesEndpoint: "https://graph.microsoft.com/v1.0/me/drives",
    graphMailMIME:"https://graph.microsoft.com/v1.0/me/messages/"
}

export const msalInstance = new PublicClientApplication(msalConfig)