import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { getToken, removeToken } from '@/utils/storage'
import { appAxios } from '@/utils/appAxios'
const API_PREFIX = process.env.VUE_APP_API_PREFIX

export const useUserSession = defineStore('userSession', () => {
    const token = ref(getToken())
    const user = ref()
    const loading = ref(true)

    const isLoggedIn = computed(
        () => token.value !== undefined && token.value !== ''
    )

    function setUser(newUser) {
        user.value = newUser
    }

    function setToken(newToken) {
        token.value = newToken
    }

    function setLoading(newLoading) {
        loading.value = newLoading
    }

    async function logoutUser() {
        token.value = ''
        user.value = undefined
        removeToken()
    }

    async function getUserDetails() {
        await appAxios.get(`${API_PREFIX}/user`).then(response => {
            setUser(response)
        })
    }

    function getAuthToken(data) {
        return appAxios.post('/api/oauth/token', {
            client_id: 2,
            client_secret: "e94B3upi3eCTEYD0SXLSyYIw3tlvyBJkH7w1CI5q",
            username: data.username,
            password: data.password,
            grant_type: 'password',
            provider: 'employees'
        })
    }

    async function getAuthTokenWithMsal(data) {
        return await appAxios.post(`${API_PREFIX}/login`, {
            access_token: data.access_token,
            refresh_token: data.refresh_token,
            email: data.email,
            name: data.name
        })
    }


    return {
        user,
        token,
        isLoggedIn,
        loading,
        logoutUser,
        setUser,
        setToken,
        setLoading,
        getUserDetails,
        getAuthToken,
        getAuthTokenWithMsal
    }
})
