import axios from "axios";
import { useUserSession } from '../store/UserSession'
import { getToken } from './storage'
import router from '../router'

const API_HOST = process.env.VUE_APP_API_HOST

export const instance = axios.create({
    baseURL: API_HOST || '/',
    timeout: 20000
})

instance.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
        (config.headers).Authorization = 'Bearer ' + token;
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
}, error => {
    return Promise.reject(error);
})

instance.interceptors.response.use(res => {
    const { data, status } = res

    if (status === undefined) {
        return Promise.reject(new Error(''))
    }
    return data

}, error => {

    const { data, status } = error.response

    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        if (error.response.data.message) {
            console.log(error.response.data.message)
        } else {
            console.log(error.message)
        }
    }

    switch (status) {
        case 404:
            if (data.error == 'DUPLICATE_ENTRY') {
                console.error('Duplicate entry found. Please check your payload.')
                return Promise.reject(new Error(data.message || 'Error'))
            }
            return Promise.reject(new Error(data.message || 'Error'))
        case 422:
            return Promise.reject(new Error(data.message || 'Unprocessable Entity'))
        case 401:
            const userSession = useUserSession()
            userSession.logoutUser()
            router.push("SignInPage")
            return data

        default:
            if (error) return Promise.reject(error)
            return data
    }
})

export const appAxios = {
    get: (url, params) => instance.get(url, { params }),
    post: (url, params, options) => instance.post(url, params, options),
    put: (url, params) => instance.put(url, params),
    delete: (url, params) => instance.delete(url, { params }),
    head: (url, params) => instance.head(url, { params }),
    options: (url, params) => instance.options(url, { params }),
    patch: (url, params) => instance.patch(url, params)
};
