import { createApp } from 'vue'
import { createPinia } from 'pinia';
import axios from 'axios';
import ElementPlus from 'element-plus'

import { msalInstance } from "./utils/msal/config"
import { msalPlugin } from "./utils/msal/plugin"
import { showNotification } from "./utils/notification"
import { appAxios } from './utils/appAxios';
import App from './App.vue'
import router from './router'

import 'element-plus/dist/index.css'
import './index.css'
//test

window.Office.onReady(() => {
    const app = createApp(App)
    app.use(createPinia())
    app.use(ElementPlus)
    app.use(router)
    app.use(msalPlugin, msalInstance, showNotification)
    app.config.globalProperties.$axios = axios
    app.config.globalProperties.$appAxios = appAxios

    app.mount('#app');
})
