import { createRouter, createWebHashHistory } from "vue-router"
import { useUserSession } from './store/UserSession'

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: () => import("@/views/Home")
    },
    {
        path: "/memorandum-details",
        name: "MemorandumDetails",
        component: () => import("@/views/MemorandumDetails")
    },
    {
        path: "/new-client/:client_type/:id",
        name: "NewClient",
        component: () => import("@/views/NewClient")
    },
    {
        path: "/commands",
        name: "CommandsPage",
        component: () => import("@/views/Command")
    },
    {
        path: "/success",
        name: "SuccessPage",
        component: () => import("@/views/Success")
    },
    {
        path: "/sign-in",
        name: "SignInPage",
        component: () => import("@/views/SignInMicrosoft")
    }
]

const router = createRouter({
    routes,
    history: createWebHashHistory()
})

const whiteList = ['/sign-in', '/redirect']

router.beforeEach(async (to, from, next) => {
    const userSession = useUserSession()
    if (userSession.isLoggedIn) {
        if (to.path === '/sign-in') {
            next({ path: '/' })
        } else {
            try {
                await userSession.getUserDetails()
                next()
            } catch (err) {
                userSession.logoutUser()
                next(`/sign-in?redirect=${to.path}`)
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/sign-in?redirect=${to.path}`)
        }
    }
})
export default router
